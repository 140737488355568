<template>
  <div>
    <div class="heading">
      Forgot Password
    </div>
    <div>
      <div v-if="error" class="notification error">{{ error }}</div>
      <form v-if="!success" v-on:submit.prevent="sendLinkNow">
        <div class="form-control">
          <label for="email">Email</label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="richard@arkgroup.org"
            required
            v-model="credentials.email"
          />
        </div>
        <button type="submit" class="button wide submit">{{ button }}</button>
      </form>
      <div v-if="success">
        <p>Please check your email for password reset link.</p>
        <p>
          Feel free to contact us at
          <a href="mailto:light@arkgroup.org">light@arkgroup.org</a> in case you
          don't receive a password reset link within next 5 minutes.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      credentials: {
        email: ''
      },
      success: false,
      error: '',
      button: 'Send Password Reset Link'
    };
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions(['forgotPassword']),
    sendLinkNow: function() {
      this.error = '';
      this.button = 'Sending Password Reset Link...';
      this.forgotPassword(this.credentials)
        .then(() => {
          this.success = true;
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            let errors = [];
            Object.values(error.response.data).forEach(value => {
              errors = errors.concat(value);
            });
            this.error = errors.join(' ');
          } else {
            this.error = 'Something went wrong. Please try again later.';
          }
          this.button = 'Send Password Reset Link';
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
