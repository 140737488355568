<template>
  <div>
    <div class="heading">
      Reset Password
    </div>
    <div>
      <div v-if="error" class="notification error">{{ error }}</div>
      <form v-if="!success" v-on:submit.prevent="sendLinkNow">
        <div class="form-control">
          <label for="email">Email</label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="richard@arkgroup.org"
            required
            v-model="credentials.email"
          />
        </div>
        <div class="form-control">
          <label for="password">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="SecurePassword123"
            required
            v-model="credentials.password"
          />
        </div>
        <div class="form-control">
          <label for="password_confirmation">Confirm Password</label>
          <input
            type="password"
            name="password"
            id="password_confirmation"
            placeholder="SecurePassword123"
            required
            v-model="credentials.password_confirmation"
          />
        </div>
        <button type="submit" class="button wide submit">{{ button }}</button>
      </form>
      <div v-if="success">
        <p>
          Password changed.
          <router-link to="/sign-in">Sign in</router-link> now!
        </p>
        <p>
          Feel free to contact us at
          <a href="mailto:light@arkgroup.org">light@arkgroup.org</a> in case you
          still unable to sign in using your email and a new password.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      credentials: {
        email: '',
        password: '',
        password_confirmation: '',
        reset: ''
      },
      success: false,
      error: '',
      button: 'Reset Password'
    };
  },
  created() {},
  mounted() {
    this.credentials.reset = this.$route.params.reset;
  },
  methods: {
    ...mapActions(['resetPassword']),
    sendLinkNow: function() {
      this.error = '';
      this.button = 'Resetting Password...';
      this.resetPassword(this.credentials)
        .then(() => {
          this.success = true;
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            let errors = [];
            Object.values(error.response.data).forEach(value => {
              errors = errors.concat(value);
            });
            this.error = errors.join(' ');
          } else {
            this.error = 'Something went wrong. Please try again later.';
          }
          this.button = 'Reset Password';
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
