<template>
  <div>
    <div class="heading">
      Sign In
    </div>
    <div>
      <div v-if="error" class="notification error">{{ error }}</div>
      <form v-on:submit.prevent="signInNow">
        <div class="form-control">
          <label for="email">Email</label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="richard@arkgroup.org"
            required
            v-model="credentials.email"
          />
        </div>
        <div class="form-control">
          <label for="password">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="SecurePassword123"
            required
            v-model="credentials.password"
          />
        </div>
        <button type="submit" class="button wide submit">{{ button }}</button>
      </form>
      <div class="forgot-password">
        <router-link to="/password/reset">Forgot password?</router-link>
      </div>
      <div class="no-account">
        Don't have an account yet?
        <router-link to="/sign-up">Sign up!</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      credentials: {
        email: '',
        password: ''
      },
      error: '',
      button: 'Sign In'
    };
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions(['signIn']),
    signInNow: function() {
      this.error = '';
      this.button = 'Signing In...';
      this.signIn(this.credentials)
        .then(() => {
          this.$router.push(this.$route.query.redirect || '/dashboard');
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            this.error =
              'Wrong password. Make sure you are using valid credentials.';
          } else {
            this.error = 'Something went wrong. Please try again later.';
          }
          this.button = 'Sign In';
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
